import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.array.filter.js";
import _toConsumableArray from "/mnt/c/Users/scaas/Desktop/tinymac/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray";
import "core-js/modules/es6.function.name.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.slice.js";
import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.symbol.js";
import "core-js/modules/es6.array.from.js";
import "core-js/modules/es6.string.iterator.js";
import "core-js/modules/es6.array.iterator.js";
import "core-js/modules/web.dom.iterable.js";

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it.return != null) it.return();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var util = {};

util.title = function (title) {
  title = title || 'Luckylucky Supply Chain Cloud - FBA Pioneer';
  window.document.title = title;
};

util.getMenuByName = function (name, menulist) {
  var menu = {};

  var forFn = function forFn(name, menulist) {
    var _iterator = _createForOfIteratorHelper(menulist),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var item = _step.value;

        if (item.name === name) {
          menu = item;
        } else {
          if (item.children && item.children.length > 0) {
            forFn(name, item.children);
          }
        }

        if (menu.name) {
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  };

  forFn(name, menulist);
  return menu;
};

util.oneOf = function (ele, targetArr) {
  if (targetArr.indexOf(ele) >= 0) {
    return true;
  } else {
    return false;
  }
};

util.getParentMenusByName = function (openAccesseMenu, name) {
  var temp = [];

  var forFn = function forFn(openAccesseMenu, name) {
    var _iterator2 = _createForOfIteratorHelper(openAccesseMenu),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var item = _step2.value;

        if (item.name === name && item.path !== "/") {
          temp.push(item);
          forFn(openAccesseMenu, item.parentName);
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  };

  forFn(openAccesseMenu, name);
  temp.reverse();
  return temp;
};

util.handleTitle = function (vm, item) {
  return item.title;
};

util.setCurrentPath = function (vm, name) {
  var openAccesseMenu = vm.$store.state.app.openAccessMenu;
  var menulistWithParent = util.getParentMenusByName(openAccesseMenu, name);
  var currentPathArr = [];

  if (!menulistWithParent.some(function (item) {
    return item.name === "home_index";
  })) {
    currentPathArr.push({
      title: vm.$t("首页"),
      path: "/home",
      name: 'home_index'
    });
  }

  currentPathArr.push.apply(currentPathArr, _toConsumableArray(menulistWithParent));
  vm.$store.commit('setCurrentPath', currentPathArr);
};

util.setCurrentModule = function (vm, name) {
  var openAccesseMenu = vm.$store.state.app.openAccessMenu;
  var moduleList = vm.$store.state.app.moduleList;
  var currentModule = vm.$store.state.app.currentModule;
  var menulistWithParent = util.getParentMenusByName(openAccesseMenu, name);

  if (menulistWithParent.length > 0) {
    var moduleName = menulistWithParent[0].name;

    if (moduleList.some(function (item) {
      return item.name === moduleName;
    }) && currentModule !== moduleName) {
      vm.$store.dispatch('changeModule', moduleName);
    }
  }
};

util.toDefaultPage = function (menulist, to, routes, next) {
  if (to.path == "/") {
    next({
      name: 'home_index',
      replace: true
    });
  } else {
    next();
  }
};

util.openAccesseMenu = function (accesseMenu) {
  var openAccesseMenu = [];

  var forFn = function forFn(menulist, parentName) {
    var _iterator3 = _createForOfIteratorHelper(menulist),
        _step3;

    try {
      for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
        var item = _step3.value;
        item.parentName = parentName;
        openAccesseMenu.push(item);

        if (item.children && item.children.length > 0) {
          forFn(item.children, item.name);
        }
      }
    } catch (err) {
      _iterator3.e(err);
    } finally {
      _iterator3.f();
    }
  };

  forFn(accesseMenu, '');
  return openAccesseMenu;
};

util.openNewPage = function (vm, name, argu, query) {
  var pageOpenedList = vm.$store.state.app.pageOpenedList;
  var openedPageLen = pageOpenedList.length;
  var i = 0;
  var tagHasOpened = false;

  while (i < openedPageLen) {
    if (name === pageOpenedList[i].name) {
      // 页面已经打开
      vm.$store.commit('pageOpenedList', {
        index: i,
        argu: argu,
        query: query
      });
      tagHasOpened = true;
      break;
    }

    i++;
  }

  if (!tagHasOpened) {
    var tags = vm.$store.state.app.openAccessMenu.filter(function (item) {
      return name === item.name;
    });

    if (tags.length > 0) {
      var tag = tags[0];

      if (argu) {
        tag.argu = argu;
      }

      if (query) {
        tag.query = query;
      }

      vm.$store.commit('increateTag', tag);
    }
  }
};

export default util;